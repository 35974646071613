import React, { useEffect, useState } from 'react';
import { useBeat } from './Beat';
import './SoundButton.css';

function SoundButton(props: { interval: number, count: number }) {
    const [flash, setFlash] = useState<boolean>(false);
    const [flashColor, setFlashColor] = useState<string>("white");
    const [selected, setSelected] = useState<boolean>(false);
    const { addBeatCallback, setEnabled } = useBeat();

    //Whenever flash is set to true, set it back to false after 500ms
    useEffect(() => {
        if(flash) {
            const timeout = setTimeout(() => {
                setFlash(false);
            }, 100);
            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [flash]);

    //flash when the beat plays
    useEffect(() => {
        addBeatCallback(props.interval, props.count, (beatSource) => {
            setFlashColor(beatSource.color);
            setFlash(true);
        });
    }, []);

    //render component
    return (
        <div
            className={`button`}
            onClick={() => {
                setSelected(!selected);
                setEnabled(props.interval, props.count, !selected);
            }}
            style={{
                backgroundColor: selected ? "rgb(130, 130, 130)" : "transparent"
            }}
        >
            <div
                className={flash ? "innerButtonFlash" : "innerButton"}
                style={{
                    backgroundColor: flash ? flashColor : "transparent"
                }}
            />
        </div>
    );
}

export default SoundButton;
import React, { createContext, useState, useEffect, useContext, useRef, useMemo } from 'react';
import * as Tone from 'tone';
import './Beat.css';

export const backendUrl = "wss://psyc-215-project-backend.onrender.com";

export interface BeatInfo {
    addBeatCallback: (interval: number, count: number, callback: (instrument: Instrument) => void) => void;
    setEnabled: (interval: number, count: number, value: boolean) => void;
    shareSourceData: () => void;
    localSource: Source;
}

export interface Instrument {
    name: string;
    color: string;
    note: string;
    path: string;
}

export interface Source {
    device: string;
    instrument: string;
    notes: {
        interval: number;
        count: number;
    }[];
}

const BeatContext = createContext<BeatInfo>({} as BeatInfo);

export const intervals = [1, 2, 4, 8, 16, 3, 6];

export const instruments: { [key: string]: Instrument } = {
    
    Kick: {
        name: "Kick",
        color: "#b2434d",
        note: "A1",
        path: "Kicks/bunchakiks40.wav"
    },
    HiHat: {
        name: "HiHat",
        color: "#facafb",
        note: "B1",
        path: "Hats/chh.wav"
    },
    Snare: {
        name: "Snare",
        color: "#e55858",
        note: "C1",
        path: "Snares/ec-sn021.wav"
    },
    HiTom: {
        name: "HiTom",
        color: "#fa8971",
        note: "D1",
        path: "Toms/ambient_tom_1.wav"
    },
    LowTom: {
        name: "LowTom",
        color: "#ef93b5",
        note: "E1",
        path: "Toms/ambient_tom_2.wav"
    },
    Ride: {
        name: "Ride",
        color: "#c971a2",
        note: "F1",
        path: "Rides/crispride.wav"
    },
    Crash: {
        name: "Crash",
        color: "#944e89",
        note: "G1",
        path: "Crashes/curecrash.wav"
    },
    Clap: {
        name: "Clap",
        color: "#96b1e7",
        note: "A2",
        path: "Claps/brightclap.wav"
    },
    Gong: {
        name: "Gong",
        color: "#4694a8",
        note: "B2",
        path: "Gongs and Super Crashes/big-boomy-gong.wav"
    },
    Cowbell: {
        name: "Cowbell",
        color: "#346376",
        note: "C2",
        path: "Western and Latin Percussion/badcow.wav"
    },
    Conga: {
        name: "Conga",
        color: "#29684a",
        note: "D2",
        path: "Western and Latin Percussion/conga_clean.wav"
    },
    LowConga: {
        name: "LowConga",
        color: "#379648",
        note: "E2",
        path: "Western and Latin Percussion/conga_clean_lower.wav"
    },
    Shaker: {
        name: "Shaker",
        color: "#79b547",
        note: "F2",
        path: "Western and Latin Percussion/darkshaker.wav"
    },
    Rattle: {
        name: "Rattle",
        color: "#b8cf61",
        note: "G2",
        path: "Western and Latin Percussion/rattle.wav"
    },
    Tambourine: {
        name: "Tambourine",
        color: "#f3db6f",
        note: "A3",
        path: "Western and Latin Percussion/tambourine-clean.wav"
    },
    Triangle: {
        name: "Triangle",
        color: "#e79055",
        note: "B3",
        path: "Western and Latin Percussion/triangleopen.wav"
    },
    Vibraslap: {
        name: "Vibraslap",
        color: "#ce6442",
        note: "C3",
        path: "Western and Latin Percussion/vibraslap.wav"
    },
    Disco: {
        name: "Disco",
        color: "#725689",
        note: "D3",
        path: "Punches Hits Discoblasts/cheezo-disco-hit.wav"
    }
};

interface IntervalData {
    count: number;
    callbacks: ((instrument: Instrument) => void)[][];
}

function Beat(props: { children: any }) {
    //the unique id of this device (should be constant within a single page load)
    const deviceId = useMemo<string>(() => Math.floor(Math.random() * 999999).toString(), []);

    //establish connection
    const websocketRef = useRef(new WebSocket(backendUrl));

    //store all the interval beat data
    const intervalDataRef = useRef<IntervalData[]>(intervals.map(interval => ({
        count: 0,
        callbacks: [...Array(interval)].map(() => [])
    })));

    //store all the sources data
    const sourcesRef = useRef<{ [key: string]: Source }>({
        localSource: {
            device: deviceId,
            instrument: "Low",
            notes: []
        }
    });

    //store whether the audio is initialized yet
    const [initialized, setInitialized] = useState<boolean>(false);

    //construct the state that we pass down (mostly for useful functions)
    const [state, setState] = useState<BeatInfo>({
        addBeatCallback: (interval, count, callback) => {
            const i = intervals.indexOf(interval);
            intervalDataRef.current[i].callbacks[count].push(callback);
        },
        setEnabled: (interval: number, count: number, value: boolean) => {
            const source = sourcesRef.current["localSource"];
            const prev = source.notes.some(e => e.count === count && e.interval === interval);
            if(value === prev){
                return;
            }else if(value === true){
                //add it
                source.notes.push({
                    interval: interval,
                    count: count
                })
            }else{
                //remove it
                source.notes = source.notes.filter(
                    e => e.count !== count || e.interval !== interval
                );
            }

            //send a message to the websocket
            websocketRef.current.send(JSON.stringify(source));
        },
        shareSourceData: () => {
            websocketRef.current.send(JSON.stringify(sourcesRef.current["localSource"]));
        },
        localSource: sourcesRef.current["localSource"]
    });

    //function that sets up all the loops
    const init = async () => {
        //if we're already initialized, don't re-initialize
        if(initialized){ return; }

        //wait for the library to start up (requires user input)
        await Tone.start();

        //create a map from notes to urls
        const urls: { [key: string]: string } = {};
        Object.keys(instruments).forEach(iName => {
            const i = instruments[iName];
            urls[i.note] = i.path;
        })
    
        //set the intervals on a loop
        intervals.forEach((interval, i) => {
            const sampler = new Tone.Sampler({
                urls: urls,
                baseUrl: process.env.PUBLIC_URL + '/sounds/'
            }).toDestination();;
            const data = intervalDataRef.current[i];

            //set the callback when this interval fires
            new Tone.Loop(time => {
                const allSourceNames = Object.keys(sourcesRef.current);
                allSourceNames.forEach((sourceName) => {
                    const source = sourcesRef.current[sourceName];
                    if(source.notes.some(e => e.count === data.count && e.interval === interval)){
                        const instrument = instruments[source.instrument];
                        if(instrument !== undefined){
                            sampler.triggerAttackRelease(instrument.note, `${interval}n`, time);
                            data.callbacks[data.count].forEach(f => f(instrument));
                        }
                    }
                });
                data.count = (data.count + 1) % interval;
            }, `${interval}n`).start(0);
        });
    
        //start the loop, and sync it up to the 4th second since epoch
        Tone.Transport.bpm.value = 120;
        Tone.Transport.start(`+${2 - (Date.now() % 2000) / 1000}`);

        //mark that we're initialized
        if(!initialized){
            setInitialized(true);
        }
    }

    //setup websocket behavior
    useEffect(() => {
        //connection opened
        websocketRef.current.addEventListener('open', () => {
            websocketRef.current.send(JSON.stringify(state.localSource));
        });

        //listen for messages
        websocketRef.current.addEventListener('message', (event) => {
            console.log("recieved", event.data);
            const recievedSource: Source = JSON.parse(event.data) as Source;
            sourcesRef.current[recievedSource.device] = recievedSource;
        });
    }, []);
    
    //render
    return <BeatContext.Provider value={state}>
        {initialized ?
            props.children
        :
            <div className='startButton' onClick={() => init()}>
                ▶
            </div>
        }
    </BeatContext.Provider>;
}

export default Beat;

export function useBeat() {
    return useContext(BeatContext);
}
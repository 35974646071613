import React from 'react';
import './App.css';
import ButtonRow from './components/ButtonRow';
import Beat, { intervals } from './components/Beat';
import SoundSelector from './components/SoundSelector';

function App() {
  return (
    <div className="App">
      <h1>Levi's PSYC 215 Project</h1>
      <Beat>
        <SoundSelector />
        {intervals.map(i => <ButtonRow key={"buttonRow_" + i} interval={i} />)}
      </Beat>
    </div>
  );
}

export default App;

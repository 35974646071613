import React from 'react';
import './ButtonRow.css'
import SoundButton from './SoundButton';

function ButtonRow(props: { interval: number }) {
  return (
    <div className="row">
        {[...Array(props.interval)].map((_, i) => <SoundButton key={"button_"+i} interval={props.interval} count={i} />)}
    </div>
  );
}

export default ButtonRow;
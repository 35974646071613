import React, { useEffect, useState } from 'react';
import './SoundSelector.css';

import { instruments, useBeat, Instrument } from './Beat';

const SoundSelector = () => {
    const { localSource, shareSourceData } = useBeat();
    const [selection, setSelection] = useState<string>(Object.keys(instruments)[0]);

    useEffect(() => {
        localSource.instrument = selection;
        shareSourceData();
    }, [localSource, selection]);

    return <div className="soundSelectorDiv">
        <div className="soundSelectorTitle">Your Sound:</div>
        <select
            className="soundSelector"
            value={selection}
            onChange={(e) => { setSelection(e.target.value); }}
            style={{color: instruments[selection].color}}
        >
            {Object.keys(instruments).map((name) =>
                <option
                    value={name}
                    key={"Option_"+name}
                    style={{color: instruments[name].color}}
                >
                    {instruments[name].name}
                </option>)
            }
        </select>
    </div>;
}

export default SoundSelector;